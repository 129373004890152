window.applyItalicI = function() {
    const fanucis = document.querySelectorAll('h1, h2, h3, h4, .apply-italic-i');

    fanucis.forEach((fanuci) => {
        if (!fanuci.dataset.italicApplied) {  // Prevent re-processing the same element
            fanuci.innerHTML = fanuci.innerHTML
                .replace(/([0-9])i(?!<)/g, '$1<em class="italic-i">i</em>')  // Prevents re-wrapping
                .replace(/iHMI/g, '<em class="italic-i">i</em>HMI')
                .replace(/iB5/g, '<em class="italic-i">i</em>B5')
                .replace(/MT-LINK i/g, 'MT-LINK <em class="italic-i">i</em>')
                .replace(/Manual Guide i/g, 'Manual Guide <em class="italic-i">i</em>')
                .replace(/iRVision/g, '<em class="italic-i">i</em>RVision')
                .replace(/iRPickTool/g, '<em class="italic-i">i</em>RPickTool')
                .replace(/iRCalibration/g, '<em class="italic-i">i</em>RCalibration');

            fanuci.dataset.italicApplied = "true"; // Mark as processed
        }
    });
};

// ✅ Run once on page load
document.addEventListener("DOMContentLoaded", () => {
    applyItalicI();
});

// ✅ Use MutationObserver (Only for New Elements, Not Every Change)
const observer = new MutationObserver(() => {
    setTimeout(() => {
        if (typeof applyItalicI === "function") {
            applyItalicI();
        }
    }, 50);
});

observer.observe(document.body, { childList: true, subtree: true });
